<template>
<TabView class="custom-tabview"> 
    <TabPanel header="Hotel List">
        <div class="hotel-list">
            <div class="search-bar">
                <div class="p-input-icon-left search-field">
                    <i class="pi pi-search" />
                    <InputText type="text" @keyup="searchHotel($event)" placeholder="Search by hotel name" />
                </div>
                <Button v-if="can('create', 'hotel')" label="Add" @click="openHotelFormDialog('add')" class="p-button-sm" icon="pi pi-plus" />    
            </div>

            <div class="filter-bar">
                <div class="p-fluid p-grid p-field">
                    <div class="p-col p-mb-2">
                        <div style="display: flex;">
                            <label class="p-mr-2" for="filter_rating">Rating:</label>
                            <Dropdown v-model="selectedRatingFilter" style="width: 100%" :options="filterRating" optionLabel="rating">
                            </Dropdown> 
                        </div>
                    </div>
                    <div class="p-col">
                        <div style="display: flex;">
                            <label class="p-mr-2" style="justify-content: center; align-items: center" for="filter_province">City/Province:</label>
                            <Dropdown v-model="selectedProvinceFilter" style="width: 100%" :options="filterProvinceDropdown" optionLabel="province_name">
                            </Dropdown>
                        </div>
                    </div>

                    <div class="p-col-fixed filter-buttons">
                        <Button label="Filter" @click="filterHotel()" class="p-mr-1 p-button-sm p-button-warning" icon="pi pi-filter" />
                        <Button label="Clear" @click="clearFilterHotel()" class="p-button-sm p-button-secondary" icon="pi pi-filter-slash" />
                    </div>
                </div>
            </div>

            <div class="content-section implementation">
                <div class="hotel-datatable card">
                    <DataTable
                        :value="hotelsList"
                        responsiveLayout="scroll"
                        v-model:selection="selectedHotel" 
                        selectionMode="single"
                        @rowSelect="onRowSelect"
                        dataKey="id"
                        :lazy="true" 
                        :paginator="true"
                        :rows="7"
                        @page="onPage($event)"
                        :totalRecords="totalRecords"
                        ref="hotelDataTable"
                    >
                        <Column field="id" header="ID" :style="{'min-width':'50px'}"></Column>
                        <Column field="hotel_name" header="Hotel name" :style="{'min-width':'200px'}"></Column>
                        <Column field="rating" header="Ratings" :style="{'min-width':'130px'}">
                            <template #body="slotProps">
                                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
                            </template>
                        </Column>
                        <Column field="province" header="City/Province" :style="{'min-width':'140px'}"></Column>
                        <Column field="tel" header="Tel." :style="{'min-width':'110px'}"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="website" header="Website"></Column>
                        <Column field="address" header="Address" :style="{'max-width':'220px'}">
                            <template #body="slotProps">
                                <div class="truncate-addr">
                                    {{slotProps.data.address}}
                                </div>
                            </template>
                        </Column>

                        <Column v-if="can('update', 'hotel') || can('delete', 'hotel')" :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                            <template #body="slotProps">
                                <Button v-if="can('update', 'hotel')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="openHotelFormDialog('update', slotProps.index)"/>
                                <Button v-if="can('delete', 'hotel')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteHotel(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                    <ConfirmDialog></ConfirmDialog>
                </div> 

                <Dialog v-model:visible="showHotelFormDialog" :style="{width: '50rem', margin: '0px 5px'}" :modal="true">
                    <template #header>
                        <h5 style="margin: 0px">{{dialogHeader}}</h5>
                    </template>
                    <HotelForm :FormType="form_type" @RefreshHotelList="getHotelsData()" />
                </Dialog>
                
                <div class="tabmenu card">
                    <TabView lazy class="tabview-custom-header sub-tabview">
                        <TabPanel>
                            <template #header>
                                <i class="pi pi-phone"></i>
                                <span>Contact Person</span>
                            </template>
                            <div v-if="displayComponent">
                                <HotelContactPerson />
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
        </TabPanel>

    <TabPanel header="Contract Rate">
        <HotelContractRate />
    </TabPanel>
</TabView>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import HotelForm from '../components/HotelForm.vue'
import HotelContractRate from './HotelContractRate.vue'
import HotelContactPerson from '../components/HotelContactPerson.vue'

import HotelService from '../service/HotelService'
import CambodiaProvinceService from '../service/CambodiaProvinceService'

export default {
    setup() {
        const { can } = useAbility()
        const store = useStore()
        const confirm = useConfirm()
        const toast = useToast()

        let selectedHotel = ref()
        let hotelsList = ref([])
        const displayComponent = ref(false)
        const hotelDataTable = ref()
        const lazyParams = ref({})
        const totalRecords = ref(0)
        
        let dialogHeader = ref('')
        let form_type = ref('')
        const showHotelFormDialog = ref(false)

        let filterProvinceDropdown = ref([])
        let filterRating = ref([
            {rating: 'Any', star: 0},
            {rating: '1 Star', star: 1},
            {rating: '2 Star', star: 2},
            {rating: '3 Star', star: 3},
            {rating: '4 Star', star: 4},
            {rating: '5 Star', star: 5},
        ])
        const selectedProvinceFilter = ref()
        const selectedRatingFilter = ref()

        //On Create
        const hotelService = ref(new HotelService())
        const provinceService = ref(new CambodiaProvinceService())

        onMounted(() => {
            lazyParams.value = {
                first: 0,
                rows: hotelDataTable.value.rows,
            };

            getHotelsData()
            getProvinces()

            selectedRatingFilter.value = filterRating.value[0]
        })

        //Method
        const onRowSelect = (event) => {
            store.dispatch('setContactPerson_HotelID', event.data.id)
            store.dispatch('setIsHotelContactList', true)
            displayComponent.value = true
        }

        const getHotelsData = () => {
            hotelService.value.getHotelsLazy({lazyEvent: JSON.stringify(lazyParams.value)}).then((data) => {
                if(!data.errorResponse) {
                    hotelsList.value = data.data;
                    totalRecords.value = data.totalrecords;
                } else {
                    toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            })
        }

        const onPage = (event) => {
            lazyParams.value = event;
            getHotelsData();
        }

        let timeout;
        const searchHotel = (event) => {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                const hotelName = event.target.value.trim()
                if(hotelName != '') {
                    hotelService.value.filterHotelByName(event.target.value).then(data => {
                        if(!data.errorResponse) {
                            hotelsList.value = data.data;
                            totalRecords.value = data.totalrecords;
                        } else {
                            toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 5000});
                            hotelsList.value.length = 0;
                        }
                    })
                } else {
                    getHotelsData()
                }
            }, 300);
        }

        const openHotelFormDialog = (formtype, hotelindex) => {
            showHotelFormDialog.value = true
            form_type.value = formtype
            
            if(formtype == 'add'){
                dialogHeader.value = 'Add Hotel'
            } else {
                dialogHeader.value = 'Update Hotel'
                store.dispatch('hotel_updateform', hotelsList.value[hotelindex])
            }
        }

        const confirmDeleteHotel = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.hotel_name +'" ?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const hotel_id = data.id
                    hotelService.value.deleteHotel(hotel_id).then((data) => {
                        if(!data.errorResponse){
                            getHotelsData()
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});
                        }
                    })
                }
            });
        }

        const getProvinces = () => {
            provinceService.value.getProvinces().then(data => {
                filterProvinceDropdown.value = [{id: 0, province_name: 'Any'}]
                for(const item of data) {
                    filterProvinceDropdown.value = [...filterProvinceDropdown.value, 
                        {id: item.id, province_name: item.province_name}
                    ]
                }
                selectedProvinceFilter.value = filterProvinceDropdown.value[0]
            });
        }

        const filterHotel = () => {
            const ratingFilter = selectedRatingFilter.value
            const provinceFilter = selectedProvinceFilter.value

            if(ratingFilter.id == 0 && provinceFilter.id == 0){
                getHotelsData()
            } else {
                hotelService.value.filterHotel(ratingFilter, provinceFilter).then(data => {
                    hotelsList.value = data
                })
            }
        }

        const clearFilterHotel = () => {
            getHotelsData()
            selectedRatingFilter.value = filterRating.value[0]
            selectedProvinceFilter.value = filterProvinceDropdown.value[0]
        }

        return {
            can,
            hotelsList,
            selectedHotel,
            onRowSelect,
            displayComponent,
            hotelDataTable,
            onPage,
            totalRecords,
            searchHotel,

            openHotelFormDialog,
            showHotelFormDialog,

            dialogHeader,
            form_type,

            getHotelsData,
            confirmDeleteHotel,

            filterProvinceDropdown,
            filterRating,
            selectedProvinceFilter,
            selectedRatingFilter,
            filterHotel,
            clearFilterHotel,
        }
    },
    components: {
        HotelForm,
        HotelContractRate,
        HotelContactPerson,
    }
}
</script>

<style lang="scss" scoped>
.hotel-datatable {
    height: 390px;
}

.tabmenu {
    height: auto;
    min-height: 21.5rem;
    padding: 0px 10px;
}
</style>