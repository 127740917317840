import axios from "../axios.instance";

const CancelToken = axios.CancelToken;
let source = '';

export default class HotelContactPersonService {
    async getHotelContactPerson(id) {        
        try {
            source = CancelToken.source();
            const res = await axios.get('/hotelcontactperson/'+id, {
                cancelToken: source.token
            })
            return res.data
            // if(res.data.length > 0){
            //     return res.data
            // } else {
            //     return []
            // }
        } catch (error) {
            // console.log(error.message)
            return error.response.data
        }
    }
    
    cancelRequest() {
        source.cancel('Request Canceled');
    }

    async addHotelContactPerson(data) {
        try {
            if(data != ''){
                const res = await axios.post('/hotelcontactperson', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateHotelContactPerson(contact_person_id, data) {
        try {
            if(contact_person_id != 0){
                const res = await axios.put('/hotelcontactperson/'+contact_person_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteHotelContactPerson(contact_person_id) {
        try {
            if(contact_person_id != 0) {
                const res = await axios.delete('/hotelcontactperson/'+contact_person_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}