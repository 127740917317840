<template>
    <div class="hotel-contract">
        <div class="filter-bar">
            <div class="p-fluid p-grid">
                <div class="p-col-12 p-md-6 p-sm-6">
                    <div style="display: flex;">
                        <label class="p-mr-2" style="min-width: 80px" for="hotel_name">Hotel name:</label>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <AutoComplete inputClass="custom-autocomplete-icon" v-model="selectedHotel" :suggestions="filteredHotels" @complete="searchHotel($event)" @item-select="onHotelNameSelect($event)" field="hotel_name" />
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-sm-6">
                    <div style="display: flex;">
                        <label class="p-mr-2" style="min-width: 80px" for="contract_name">Contract:</label>
                        <Dropdown style="width: 100%" v-model="selectedContract" :options="contract_list" optionLabel="contract_name" >
                        </Dropdown>
                    </div>
                </div>
            </div>
            
            <div class="p-fluid p-grid" style="margin-top: 0px">
                <label for="contract_period" style="width: 120px" class="p-col-12 p-md-1 p-lg-1">Contract period:</label>
                <div class="p-col">
                    <Dropdown v-model="selectedPeriod" :options="contractPeriod_List" optionLabel="validity" >
                    </Dropdown> 
                </div>
                <div class="p-col-fixed">
                    <Button label="Update Contract" @click="openHotelContractForm()" style="width: 8.7rem" class="p-button-sm" />
                </div>
            </div>
        </div>

        <Panel class="rate-panel" header="Room rate">
            <div class="p-fluid p-grid p-field" style="align-items: center">
                <div class="p-col">
                    <!-- <Dropdown v-model="selectedSeasonDropdown" :options="seasonDropdown" style="width: 20rem" optionLabel="season" >
                    </Dropdown>  -->
                    <div class="p-field-radiobutton p-mt-3 p-ml-2">
                        <RadioButton id="low-season" name="city" value="Low Season" v-model="hotelSeasonal" :disabled="contractPeriod_List.length == 0" />
                        <label for="low-season" class="p-mr-4">Low Season</label>

                        <RadioButton id="high-season" name="city" value="High Season" v-model="hotelSeasonal" :disabled="contractPeriod_List.length == 0" />
                        <label for="high-season" class="p-mr-4">High Season</label>
                        
                        <RadioButton id="peak-season" name="city" value="Peak Season" v-model="hotelSeasonal" :disabled="contractPeriod_List.length == 0" />
                        <label for="peak-season" class="p-mr-4">Peak Season</label>
                    </div>
                </div>
                <!-- <div class="p-col-fixed p-mr-2">
                    <Button label="Refresh" class="p-button-sm p-button-secondary" icon="pi pi-refresh" @click="refreshRoomRate()" />
                </div> -->
            </div>
            <DataTable class="hotel-rate" :value="roomRate" v-model:selection="selectedRoomRate" selectionMode="single" responsiveLayout="scroll" showGridlines>
                <ColumnGroup type="header">
                    <Row>
                        <Column header="Room Type/Category" :rowspan="3" :style="{'width':'350px'}" />
                    </Row>
                    <Row>
                        <Column header="SGL" colspan="2" />
                        <Column header="DBL/TWN" :colspan="2" />
                        <Column header="TRP" :colspan="2" />
                    </Row>
                    <Row>
                        <Column header="FIT" :style="{'width':'120px'}"/>
                        <Column header="GIT" :style="{'width':'120px'}"/>
                        <Column header="FIT" :style="{'width':'120px'}"/>
                        <Column header="GIT" :style="{'width':'120px'}"/>
                        <Column header="FIT" :style="{'width':'120px'}"/>
                        <Column header="GIT" :style="{'width':'120px'}"/>
                    </Row>
                </ColumnGroup>
                <Column field="room_type" />
                <Column field="sgl_fit" bodyStyle="text-align: center" />
                <Column field="sgl_git" bodyStyle="text-align: center" />
                <Column field="dbl_fit" bodyStyle="text-align: center" />
                <Column field="dbl_git" bodyStyle="text-align: center" />
                <Column field="trp_fit" bodyStyle="text-align: center" />
                <Column field="trp_git" bodyStyle="text-align: center" />
            </DataTable>
            <div class="p-fluid p-grid p-mt-2">
                <div class="p-col-fixed p-ml-0 p-mt-1">
                    <label>Extra bed: </label>
                </div>
                <div class="p-col">
                    <InputNumber inputStyle="max-width: 150px; text-align: center" v-model="extrabed_rate" />
                </div>
                <div class="p-col p-mr-2" style="text-align: right">
                    <span style="font-weight: 500">Rates are in USD</span>
                </div>
            </div>

            <div class="p-fluid p-grid p-mt-2">
                <div class="p-col-fixed p-ml-0">
                    <label v-if="hotelSeasonal">{{hotelSeasonal}} Period:</label>
                </div>
                <div class="p-col-fixed">
                    <div class="p-mb-2">
                        <div v-for="period of seasonPeriod" :key="period.id" class="p-mb-2">
                            {{period.season_from}} - {{period.season_to}}
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { useToast } from "primevue/usetoast";
import dayjs from 'dayjs';

import HotelService from '../service/HotelService'

export default {
    setup() {
        const toast = useToast()
        const hotels = ref()
        const selectedHotel = ref()
        const filteredHotels = ref()
        
        let contract_list = ref([])
        let selectedContract = ref()

        let contractPeriod_List = ref([])
        let selectedPeriod = ref()

        // let seasonDropdown = ref([])
        // let selectedSeasonDropdown = ref()
        const hotelSeasonal = ref()
        let seasonPeriod = ref([])

        let roomRate = ref([])
        let extrabed_rate = ref(0)
        let selectedRoomRate = ref()
        let hotel_id = ref()

        //On Create
        const hotelService = ref(new HotelService())

        //Method
        const searchHotel = (event) => {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    filteredHotels.value = [...hotels.value];
                }
                else {
                    hotelService.value.searchHotel(event.query.toLowerCase()).then(data => {
                        filteredHotels.value = data
                    })
                }
            }, 250);
        }

        const openHotelContractForm = () => {
            if(selectedHotel.value) {
                //Save contract ID in store to select current contract in update contract form

                window.open('/hotel/update-contract?hotelid='+selectedHotel.value.id, '_blank');
            }
        }

        const onHotelNameSelect = (event) => {
            hotel_id.value = event.value.id
            getContractList(hotel_id.value)

            contractPeriod_List.value.length = 0
            // selectedSeasonDropdown.value = ''
            // seasonDropdown.value.length = 0 
        }

        const getContractList = (hotelid) => {
            hotelService.value.getContract(hotelid).then((data) => {
                if(!data.errorResponse) {
                    contract_list.value = data
                    selectedContract.value = data[0]
                } else {
                    toast.add({severity:'error', summary: 'Getting Contract Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            })
        }
        
        watchEffect(() => {
            if(selectedContract.value) {
                getContractPeriod(selectedContract.value.id)
            }
        })

        function getContractPeriod(contract_id) {
            hotelService.value.getContractPeriod(contract_id).then(data => {
                contractPeriod_List.value = data.map(item => {
                    return {
                        id: item.id,
                        validity: item.valid_from + ' - ' + item.valid_to,
                        contract_id: item.contract_id
                    }
                })

                //Find index in contract period for current year and select dropdown
                if(contractPeriod_List.value) {
                    const data_idx = data.findIndex(item => dayjs(item.valid_from).isSame(dayjs(), 'year'))
                    if(data_idx == -1) {
                        selectedPeriod.value = contractPeriod_List.value[0]
                    } else {
                        selectedPeriod.value = contractPeriod_List.value[data_idx]
                    }
                }
            })
        }

        watchEffect(() => {
            //If contract period list has data add season list and select first item
            if(contractPeriod_List.value.length > 0) {
                // seasonDropdown.value = [
                // {id: 1, season: 'Low Season'},
                // {id: 2, season: 'High Season'},
                // {id: 3, season: 'Peak Season'}]

                // selectedSeasonDropdown.value = seasonDropdown.value[0]
                hotelSeasonal.value = 'Low Season'
            } else {
                hotelSeasonal.value = ''
            }
        })

        watchEffect(() => {
            if(hotelSeasonal.value) {
                getRoomRateData()
                getSeasonPeriod()
            } else {
                roomRate.value.length = 0
                extrabed_rate.value = 0
                seasonPeriod.value.length = 0
            }
        })

        function getRoomRateData () {
            const periodid = selectedPeriod.value.id
            const season = hotelSeasonal.value
            
            hotelService.value.getRoomRate(hotel_id.value, periodid, season).then((data) => {
                if(data.length > 0) {
                    roomRate.value = data
                    extrabed_rate.value = data[0].extra_bed
                }
            })
        }

        function getSeasonPeriod(){
            const season = hotelSeasonal.value
            const contractperiod_id = selectedPeriod.value.id
            hotelService.value.getSeasonPeriod(season, contractperiod_id).then(data => {
                seasonPeriod.value = data
            })
        }

        const refreshRoomRate = () => {
            getRoomRateData()
            getSeasonPeriod()
        }

        return  {
            selectedHotel,
            filteredHotels,
            searchHotel,

            onHotelNameSelect,
            contract_list,
            selectedContract,

            contractPeriod_List,
            selectedPeriod,
            seasonPeriod,
            
            // seasonDropdown,
            // selectedSeasonDropdown,
            hotelSeasonal,
            roomRate,
            extrabed_rate,
            selectedRoomRate,
            refreshRoomRate,

            openHotelContractForm,
        }
    },
}
</script>

<style lang="scss" scoped>
.rate-panel {
    margin-bottom: 1.5rem;
}
.p-input-icon-left i {
    z-index: 1;
}

.p-field-radiobutton label:hover {
    cursor: pointer;
}
</style>