<template>
<div class="hotel-form">
    <form @submit.prevent="FormType == 'add' ? addHotel() : updateHotel()">
        <div class="hotel-form">
            <div class="p-fluid"> <!-- Nest 2 -->
                <div class="p-field p-grid">
                    <label for="hotel_name" class="p-col-fixed">Hotel name*:</label>
                    <div class="p-col">
                        <InputText id="hotel_name" v-model="hotelForm.hotel_name" type="text" :class="{'p-invalid':v$.hotel_name.$error}" />                        
                        <small v-if="v$.hotel_name.$error" class="p-error">{{v$.hotel_name.required.$message.replace('Value', 'Hotel name')}}</small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="rating" class="p-col-fixed">Rating:</label>
                    <div class="p-col">
                        <InputNumber id="rating" v-model="hotelForm.rating" type="text" :class="{'p-invalid':v$.rating.$error}" />
                        <small v-if="v$.rating.$error" class="p-error">
                            {{v$.rating.$errors[0].$validator == 'minValue' ? "Rating should be &lt; 0" : "Rating should be &lt; 5"}}
                        </small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="city" class="p-col-fixed">City/Province*:</label>
                    <div class="p-col">
                        <Dropdown v-model="selectedProvinceDropdown" :options="provinceDropdown" @change="onProvinceChange()" optionLabel="province_name" :class="{'p-invalid':v$.province.$error}">
                        </Dropdown>
                        <small v-if="v$.province.$error" class="p-error">{{v$.province.required.$message.replace('Value', 'Province')}}</small>  
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="tel" class="p-col-fixed">Tel*:</label>
                    <div class="p-col">
                        <InputMask v-model="hotelForm.tel" mask="999 999-999?9" :class="{'p-invalid':v$.tel.$error}"/>
                        <small v-if="v$.tel.$error" class="p-error">{{v$.tel.required.$message.replace('Value', 'Phone number')}}</small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="email" class="p-col-fixed">Email:</label>
                    <div class="p-col">
                        <InputText id="email" v-model="hotelForm.email" type="text" :class="{'p-invalid':v$.email.$error}"/>
                        <small v-if="v$.email.$error" class="p-error">{{v$.email.$errors[0].$message}}</small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="website" class="p-col-fixed">Website:</label>
                    <div class="p-col">
                        <InputText id="website" v-model="hotelForm.website" type="text" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="address" class="p-col-fixed">Address:</label>
                    <div class="p-col">
                        <InputText id="address" v-model="hotelForm.address" type="text" />
                    </div>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-fixed">
                    <div v-if="FormType == 'add'">
                        <Button v-if="can('create', 'hotel')" label="Save" icon="pi pi-save" type="submit" class="p-button-success" />
                    </div>
                    <div v-else>
                        <Button v-if="can('update', 'hotel')" label="Update" icon="pi pi-save" type="submit" class="p-button-warning" />
                    </div>
                </div>
                <div class="p-col-fixed">
                    <InlineMessage v-if="messages.isMsgShow" :severity="messages.severity" class="p-ml-4">{{ messages.content }}</InlineMessage>
                </div>	
            </div>
        </div>
    </form>
</div>
</template>

<script>
import { ref, reactive, onMounted, computed} from 'vue';
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import useVuelidate from '@vuelidate/core';
import { required, email, minValue, maxValue } from '@vuelidate/validators';

import HotelService from '../service/HotelService'
import CambodiaProvinceService from '../service/CambodiaProvinceService'

export default {
    props: {
        FormType: String
    },
    emits: ['RefreshHotelList'],
    setup(props, { emit }) {
        const { can } = useAbility()
        const store = useStore()
        const toast = useToast()

        let hotelForm = reactive({})
        let hotel_info = reactive({}) //Get data from store to fill in form
        let hotel_id = ref(0) //Save hotel id to use in update form

        let provinceDropdown = ref([])
        const selectedProvinceDropdown = ref()

        let messages =  computed(() => store.state.showMsg)

        //On Create
        const hotelService = ref(new HotelService())
        const provinceService = ref(new CambodiaProvinceService())
        
        onMounted(() => {
            initHotelForm()

            if(props.FormType == 'update') {
                hotel_info = store.state.hotelInfo.hotelUpdateForm

                hotel_id.value = hotel_info.id
                setProvinceDropdown(hotel_info.province)
                Object.assign(hotelForm, hotel_info)
            } else {
                setProvinceDropdown()
            }
        })

        //Methods
        const initHotelForm = () =>{
            const initForm = {
                hotel_name: '',
                rating: 0,
                province: '',
                tel: '',
                email: '',
                website: '',
                address: '',
            }

            Object.assign(hotelForm, initForm)
        }

        const addHotel = () => {
            if(validateForm()) {
                hotelService.value.addHotel(hotelForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400){
                            showMessage('warn', data.message, 5000)
                        } else {
                            initHotelForm();
                            emit('RefreshHotelList')
                            showMessage('success', 'Hotel was added successfully.', 3500)
                            v$.value.$reset() //Reset validations
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Save Error ' + data.status, detail: data.errorResponse, life: 5000});
                    }
                })
            }
        }

        const updateHotel = () => {
            if(validateForm()) {
                delete hotelForm.id //Delete ID field because it doesnt necessary to update
                
                const update_data = {
                    hotel_name: hotelForm.hotel_name == hotel_info.hotel_name ? '': hotelForm.hotel_name,
                    rating: hotelForm.rating,
                    province: hotelForm.province,
                    tel: hotelForm.tel,
                    email: hotelForm.email,
                    website: hotelForm.website,
                    address: hotelForm.address,
                }
                
                hotelService.value.updateHotel(hotel_id.value, update_data).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400){
                            showMessage('warn', data.message, 5000)
                        } else {
                            showMessage('success', 'Hotel was updated successfully.', 3500)
                            emit('RefreshHotelList')
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Update Error ' + data.status, detail: data.errorResponse, life: 5000});
                    }
                })
            }
        }

        const setProvinceDropdown = async (province_name) => {
            await provinceService.value.getProvinces().then(data => provinceDropdown.value = data)

            if (province_name != ''){
                const province = provinceDropdown.value.filter(item => item.province_name == province_name)
                selectedProvinceDropdown.value = province[0]
            }
        }

        const showMessage = (severity, content, life) => {
            let message = {
                isMsgShow: true, 
                severity: severity, 
                content: content
            }

            store.dispatch('showMsg', message)
            if(life) {
                setTimeout(() => {
                    store.dispatch('showMsg', { isMsgShow: false, severity: '', content: '' })
                }, life);
            }
        }

        const onProvinceChange = () => {
            hotelForm.province = selectedProvinceDropdown.value.province_name
        }

        //Form Validations
        let rules = computed(() =>  {
            return {
                hotel_name: { required },
                rating: { minValue: minValue(1), maxValue: maxValue(5) },
                province: { required },
                tel: { required },
                email: { email },
            }
        })
        const v$ = useVuelidate(rules, hotelForm)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            can,
            v$,
            hotelForm,
            addHotel,
            updateHotel,

            provinceDropdown,
            selectedProvinceDropdown,
            onProvinceChange,

            messages,
        }
    },
}
</script>

<style lang="scss" scoped>
.hotel-form {
    margin-top: 5px;

    label {
        width: 120px;
    }
}
</style>