<template>
<transition name="show">
    <div v-if="isContactListShow" class="sub-datatable">
        <DataTable
            :value="hotelContactList"
            responsiveLayout="scroll"
            scrollHeight="230px"
            v-model:selection="selectedHotelContact" 
            selectionMode="single"
            dataKey="id"
            :loading="loading"
        >
            <Column field="title" header="Title" :style="{'width':'150px', 'min-width':'80px'}" />
            <Column field="first_name" header="First Name" :style="{'width':'250px', 'min-width':'120px'}"></Column>
            <Column field="last_name" header="Last Name" :style="{'width':'250px', 'min-width':'120px'}"></Column>
            <Column field="position" header="Position" :style="{'width':'190px', 'min-width':'130px'}"></Column>
            <Column field="phone" header="Phone" :style="{'width':'160px', 'min-width':'110px'}"></Column>
            <Column field="email" header="Email" :style="{'width':'280px'}"></Column>
            
            <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="showUpdateHotelContactForm(slotProps.index)"/>
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="deleteHotelContactPerson(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</transition>

<transition name="show">        
    <div v-if="!isContactListShow" class="emp-contact-form">
        <form @submit.prevent="form_type == 'add' ? addHotelContactPerson() : updateHotelContactPerson()">
            <div class="p-fluid">
                <div class="p-field p-grid">
                    <label for="title" class="p-col-fixed">Title*:</label>
                    <div class="p-col" >
                        <Dropdown style="width:300px" v-model="selectedTitle" @change="onTitleChange()" :options="person_title" optionLabel="title" placeholder="Select title" :class="{'p-invalid':v$.title.$error}" />
                        <small v-if="v$.title.$error" class="p-error">{{v$.title.required.$message.replace('Value', 'Title')}}</small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="first_name" class="p-col-fixed" >First name*:</label>
                    <div class="p-col">
                        <InputText id="first_name" v-model="hotelContactForm.first_name" type="text" :class="{'p-invalid':v$.first_name.$error}"/>
                        <small v-if="v$.first_name.$error" class="p-error">{{v$.first_name.required.$message.replace('Value', 'First name')}}</small>
                    </div>
                    
                </div>
                <div class="p-field p-grid">
                    <label for="last_name" class="p-col-fixed">Last name*:</label>
                    <div class="p-col">
                        <InputText id="last_name" v-model="hotelContactForm.last_name" type="text" :class="{'p-invalid':v$.last_name.$error}"/>
                        <small v-if="v$.last_name.$error" class="p-error">{{v$.last_name.required.$message.replace('Value', 'Last name')}}</small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="relation" class="p-col-fixed">Position*:</label>
                    <div class="p-col">
                        <InputText id="position" v-model="hotelContactForm.position" type="text" :class="{'p-invalid':v$.position.$error}"/>
                        <small v-if="v$.position.$error" class="p-error">{{v$.position.required.$message.replace('Value', 'Position')}}</small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="phone" class="p-col-fixed">Contact number*:</label>
                    <div class="p-col">
                        <InputMask id="phone" mask="999 999-999?9" v-model="hotelContactForm.phone" :class="{'p-invalid':v$.phone.$error}"/>
                        <small v-if="v$.phone.$error" class="p-error">{{v$.phone.required.$message.replace('Value', 'Phone number')}}</small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="email" class="p-col-fixed">Email*:</label>
                    <div class="p-col">
                        <InputText id="email" v-model="hotelContactForm.email" :class="{'p-invalid':v$.email.$error}"/>
                        <small v-if="v$.email.$error" class="p-error">{{v$.email.$errors[0].$message.replace('Value', 'Email')}}</small>
                    </div>
                </div>
            </div>
            <div class="search-bar">
                <div v-if="form_type == 'add'">
                    <Button label="Save" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                </div>
                <div v-else>
                    <Button label="Update" type="submit" class="p-button-sm p-button-warning" icon="pi pi-save"></Button>
                </div>
                <Button label="Close" @click="displayContactList(true)" class="p-button-sm p-button-secondary" icon="pi pi-times" />
                <Message class="custom-msg" :life="messages_life" :sticky="false" v-for="msg of messages" :key="msg.content" :severity="msg.severity">{{msg.content}}</Message>
            </div>
        </form>
    </div>
</transition>
<hr style="border: 0; border-top: 1px solid var(--surface-d);"/>
<div v-if="isContactListShow" class="search-bar">
    <Button label="Add Contact" @click="displayContactList(false)" class="p-button-sm" />
    <Message class="custom-msg" :life="messages_life" :sticky="false" v-for="msg of messages" :key="msg.content" :severity="msg.severity">{{msg.content}}</Message>
</div>
</template>

<script>
import { ref, reactive, onMounted, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useConfirm } from "primevue/useconfirm";
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import HotelContactPersonService from '../service/HotelContactPersonService'
export default {
    setup() {
        const store = useStore()
        const confirm = useConfirm()

        const isContactListShow = computed(() => store.state.hotelInfo.isHotelContactList);
        const hotel_id = computed(() => store.state.hotelInfo.hotelID);
        const messages = ref([]);
        let messages_life = ref(0)

        let loading = ref(false)
        let hotelContactList = ref([])
        let selectedHotelContact = ref()
        
        const person_title = [{title: 'Mr.'}, {title: 'Ms.'}, {title: 'Mrs.'}]
        let selectedTitle = ref()
        let hotelContactForm = reactive({})
        let form_type = ref('')

        //On Create
        const hotelContact = ref(new HotelContactPersonService())

        onMounted(() => {
            initHotelContactForm()
        })

        const initHotelContactForm = () =>{
            const initForm = {
                title: '',
                first_name: '',
                last_name: '',
                position: '',
                phone: '',
                email: '',
                hotel_id: '',
            }
            selectedTitle.value = ''

            Object.assign(hotelContactForm, initForm)
        }

        watchEffect((onInvalidate) => {
            const waitAPI = setTimeout(() => {
                setLoading(true)
            }, 800)
           
            hotelContact.value.getHotelContactPerson(hotel_id.value).then((data) => {
                if(!data.errorResponse) {
                    hotelContactList.value = data;
                    clearInterval(waitAPI)
                    setLoading(false)
                } else {
                    clearInterval(waitAPI)
                    setLoading(false)
                }
            });

            //Cancel previous request if hotel_id changed
            onInvalidate(() => {
                hotelContact.value.cancelRequest()
                clearInterval(waitAPI)
            })
        })
        
        const setLoading = (status) => {
            loading.value = status
        }

        const onTitleChange = () => {
            hotelContactForm.title = selectedTitle.value.title
        }

        const addHotelContactPerson = () => {
            if(validateForm()) {
                hotelContactForm.hotel_id = hotel_id.value
                
                hotelContact.value.addHotelContactPerson(hotelContactForm).then((data) => {
                    if(data.errorResponse) {
                        showMessage('error', 'Error '+data.status+': '+data.errorResponse, 4000)
                    } else {
                        initHotelContactForm()
                        showMessage('success', 'Data successfully added.', 2500)
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }
        
        const updateHotelContactPerson = () => {
            if(validateForm()){
                hotelContact.value.updateHotelContactPerson(hotelContactForm.id, hotelContactForm).then(data => {
                    if(data.errorResponse) {
                        showMessage('error', 'Update error '+data.status+': '+data.errorResponse, 4000)
                    } else {
                        showMessage('success', 'Data successfully updated.', 2500)
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        const deleteHotelContactPerson = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.first_name + ' ' + data.last_name +'"?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const contact_person_id = data.id
                    hotelContact.value.deleteHotelContactPerson(contact_person_id).then((data) => {
                        if(!data.errorResponse){
                            hotelContactList.value = hotelContactList.value.filter(val => val.id !== contact_person_id);
                        } else {
                            showMessage('error', 'Delete error '+data.status+': '+data.errorResponse, 4000)
                        }
                    })
                }
            });
        }

        const displayContactList = (isDisplay) => {
            //When contact list display
            store.dispatch('setIsHotelContactList', isDisplay)
            initHotelContactForm()
            form_type.value = 'add'
            messages.value.length = 0;
            v$.value.$reset() //Reset validations

            //Refresh data when contact form closed
            if(isDisplay === true) {
                setLoading(true)
                hotelContact.value.getHotelContactPerson(hotel_id.value).then((data) => {
                    if(!data.errorResponse) {
                        hotelContactList.value = data;
                        setLoading(false)
                    }
                });
            }
        }

        const showUpdateHotelContactForm = (form_index) => {      
            displayContactList(false)
            form_type.value = 'update'
            selectedTitle.value = {title: hotelContactList.value[form_index].title}
            Object.assign(hotelContactForm, hotelContactList.value[form_index])
        }

        const showMessage = (severity, msgContent, life) => {
            messages.value = [{severity: severity, content: msgContent}]

            messages_life.value = life
            setTimeout(() => {
                messages.value.length = 0;
            }, life)
        }

        //Form Validations
        const rules = computed(() =>  {          
            return {
                title: { required },
                first_name: { required },
                last_name: { required },
                position: { required },
                phone: { required },
                email: { required, email },
            }            
        })
        const v$ = useVuelidate(rules, hotelContactForm)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            isContactListShow,
            loading,

            hotelContactList,
            selectedHotelContact,
            displayContactList,

            person_title,  
            selectedTitle,
            hotelContactForm,
            v$,
            form_type,
            onTitleChange,
            addHotelContactPerson,
            updateHotelContactPerson,
            showUpdateHotelContactForm,
            deleteHotelContactPerson,

            messages,
            messages_life
        }
    },
}
</script>

<style lang="scss" scoped>

</style>